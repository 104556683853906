import axios from 'axios'
import authStore from 'src/stores/authStore'
const hostURL = process.env.REACT_APP_HOST_URL
const fileUploadHostURL = process.env.REACT_APP_FILE_UPLOAD_HOST_URL
const vactrackHostURL = process.env.REACT_APP_APP_SERVER_HOST_URL // DEV
const imgToPdfHostURL = 'https://f3agujjkdtveudfmeeo3hami3q0cuxus.lambda-url.us-east-1.on.aws/'

const fetchData = async (url) => {
  try {
    const response = await axios.get(`${hostURL}${url}`)
    return response.data
  } catch (error) {
    if (error.response.status === 401) {
      return { statusCode: 401, message: 'You are not authorized to send this request' }
    } else if (error.response.status === 403) {
      return { statusCode: 403, message: 'The credentials you have provided are invalid' }
    } else {
      return { statusCode: 400, message: error.response.data.message }
    }
  }
}

const postData = async (url, postData) => {
  try {
    const response = await axios.post(`${hostURL}${url}`, postData)
    return response.data
  } catch (error) {
    console.log(error)
    if (error.response.status === 401) {
      return { statusCode: 401, message: 'You are not authorized to send this request' }
    } else if (error.response.status === 403) {
      return { statusCode: 403, message: 'The credentials you have provided are invalid' }
    } else {
      return { statusCode: 400, message: error.response.data.message }
    }
  }
}

const fetchDataAuthenticated = async (url) => {
  try {
    const token = authStore.getToken()
    const response = await axios.get(`${hostURL}${url}`, {
      headers: {
        'user-token': token,
      },
    })
    return response.data
  } catch (error) {
    if (error.response.status === 401) {
      return { statusCode: 401, message: 'You are not authorized to send this request' }
    } else if (error.response.status === 403) {
      return { statusCode: 403, message: 'The credentials you have provided are invalid' }
    } else {
      return { statusCode: 400, message: error.response.data.message }
    }
  }
}

const postDataAuthenticated = async (url, postData) => {
  try {
    const token = authStore.getToken()
    const response = await axios.post(`${hostURL}${url}`, postData, {
      headers: {
        'user-token': token,
      },
    })
    return response.data
  } catch (error) {
    if (error.response.status === 401) {
      return { statusCode: 401, message: 'You are not authorized to send this request' }
    } else if (error.response.status === 403) {
      return { statusCode: 403, message: 'The credentials you have provided are invalid' }
    } else {
      return { statusCode: 400, message: error.response.data.message }
    }
  }
}

const postFileAuthenticated = async (url, formData) => {
  try {
    const token = authStore.getToken()
    const response = await axios.post(`${hostURL}${url}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'user-token': token,
      },
    })
    return response.data
  } catch (error) {
    if (error.response.status === 401) {
      return { statusCode: 401, message: 'You are not authorized to send this request' }
    } else if (error.response.status === 403) {
      return { statusCode: 403, message: 'The credentials you have provided are invalid' }
    } else {
      return { statusCode: 400, message: error.response.data.message }
    }
  }
}

const uploadFileToS3 = async (url, formData) => {
  try {
    const response = await axios.post(`${fileUploadHostURL}${url}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    if (error.response.status === 401) {
      return { statusCode: 401, message: 'You are not authorized to send this request' }
    } else if (error.response.status === 403) {
      return { statusCode: 403, message: 'The credentials you have provided are invalid' }
    } else {
      return { statusCode: 400, message: error.response.data.message }
    }
  }
}

const sendPostRequestToApp = async (url, data, token) => {
  try {
    const response = await axios.post(`${vactrackHostURL}${url}`, data, {
      headers: {
        'auth-token': token,
      },
    })

    return response.data
  } catch (error) {
    if (error.response.status === 401) {
      return { statusCode: 401, message: 'You are not authorized to send this request' }
    } else if (error.response.status === 403) {
      return { statusCode: 403, message: 'The credentials you have provided are invalid' }
    } else {
      return { statusCode: 400, message: error.response.data.message }
    }
  }
}

const downloadFile = async (url, data) => {
  try {
    const token = authStore.getToken()
    const response = await axios.post(`${hostURL}${url}`, data, {
      headers: {
        'user-token': token,
      },
      responseType: 'blob',
    })

    // Create a Blob from the response data
    const fileBlob = new Blob([response.data], { type: 'application/csv' })

    // Create a temporary URL for the Blob
    const fileUrl = window.URL.createObjectURL(fileBlob)

    // Create a temporary <a> element to trigger the download
    const tempLink = document.createElement('a')
    tempLink.href = fileUrl
    tempLink.setAttribute('download', `students.csv`) // Set the desired filename for the downloaded file

    // Append the <a> element to the body and click it to trigger the download
    document.body.appendChild(tempLink)
    tempLink.click()

    // Clean up the temporary elements and URL
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(fileUrl)
  } catch (error) {
    console.error('Error downloading CSV:', error)
  }
}

const convertImgToPdfAWS = async (postData) => {
  try {
    const response = await axios.post(`${imgToPdfHostURL}`, postData)
    return response.data
  } catch (error) {
    console.log(error)
    if (error.response.status === 401) {
      return { statusCode: 401, message: 'You are not authorized to send this request' }
    } else if (error.response.status === 403) {
      return { statusCode: 403, message: 'The credentials you have provided are invalid' }
    } else {
      return { statusCode: 400, message: error.response.data.message }
    }
  }
}

export {
  fetchData,
  postData,
  postDataAuthenticated,
  fetchDataAuthenticated,
  downloadFile,
  postFileAuthenticated,
  uploadFileToS3,
  sendPostRequestToApp,
  convertImgToPdfAWS,
}
